import React, { FC } from 'react'
import { classes, stylesheet } from 'typestyle'

/* Components =================================================================================== */
import { Box } from '../../__aeki__/components/box/box.component'
import { Text } from '../../__aeki__/components/text/text.component'

/* Constants ==================================================================================== */
import { MAX_WIDTH } from '../../routes/__home__/layout'

/* Styles ======================================================================================= */
import { createMediaQuery } from '../../__aeki__/components/style-class/style-class.utils'
import { m0mdi } from '../../__aeki__/styles/styleset/margin/m0mdi'
import { mb16 } from '../../__aeki__/styles/styleset/margin/mb16'
import { mb20 } from '../../__aeki__/styles/styleset/margin/mb20'
import { mr0smi } from '../../__aeki__/styles/styleset/margin/mr0smi'
import { mr48 } from '../../__aeki__/styles/styleset/margin/mr48'
import { mt36 } from '../../__aeki__/styles/styleset/margin/mt36'
import { mt8 } from '../../__aeki__/styles/styleset/margin/mt8'
import { mt8mdi } from '../../__aeki__/styles/styleset/margin/mt8mdi'
import { p8 } from '../../__aeki__/styles/styleset/padding/p8'
import { pb12 } from '../../__aeki__/styles/styleset/padding/pb12'
import { pl0 } from '../../__aeki__/styles/styleset/padding/pl0'
import { pt16mdi } from '../../__aeki__/styles/styleset/padding/pt16mdi'
import { pt40 } from '../../__aeki__/styles/styleset/padding/pt40'
import { pt8 } from '../../__aeki__/styles/styleset/padding/pt8'
import { w100 } from '../../__aeki__/styles/styleset/width/w100'
import { ml40 } from '../../__aeki__/styles/styleset/margin/ml40'
import { mb12 } from '../../__aeki__/styles/styleset/margin/mb12'
import { mr24 } from '../../__aeki__/styles/styleset/margin/mr24'
import { ComponentBaselineType } from '../../__aeki__/components/__core/component.types'
import { Link, useParams } from 'react-router-dom'
import { mr88 } from '../../__aeki__/styles/styleset/margin/mr88'
import { rowmdi } from '../../__aeki__/styles/styleset/row/rowmdi'
import { mb32mdi } from '../../__aeki__/styles/styleset/margin/mb32mdi'

/* Types ======================================================================================== */
type FooterType = {
  list?: { title: string; pages: { title: string; to: string }[] }[]
  isGroupSite?: boolean
}

const date = new Date()
const locale = {
  companyName: {
    ko: '(주)삼일로지스틱스',
    en: 'Samil Logistics',
  },
  companyShort: {
    ko: '삼일로지스틱스',
    en: 'About us',
  },
  home: {
    ko: '홈페이지',
    en: 'Home',
    zh: '首页',
  },
  services: {
    ko: '사업영역',
    en: 'Services',
    zh: '业务领域',
  },

  customerService: {
    ko: '고객센터',
    en: 'Customer Service',
    zh: '客户服务',
  },

  contactUs: {
    ko: '고객지원',
    en: 'Contact Us',
    zh: '联系我们',
  },

  agreement: {
    ko: '이용약관',
    en: 'Terms of Use',
    zh: '使用条款',
  },
  privacyPolicy: {
    ko: '개인정보처리방침',
    en: 'Privacy Policy',
    zh: '隐私政策',
  },
  branch: {
    ko: '사업장',
    en: 'Branch',
    zh: '分公司',
  },
  branch1: {
    ko: '본사',
    en: 'Headquarter',
    zh: '本社',
  },
  branch1Address: {
    ko: '충청남도 아산시 염치읍 아산로 645-44',
    en: '645-44, Asan-ro, Yeomchi-eup, Asan-si, Chungcheongnam-do, Republic of Korea',
    zh: '韩国忠清南道牙山市严致邑牙山路645-44',
  },
  branch2: {
    ko: '서울 영업소',
    en: 'Dunpo Branch',
    zh: '敦浦分公司',
  },
  branch2Address: {
    ko: '서울특별시 서초구 반포대로22길 77, 6층',
    en: '6F, 77, Banpo-daero 22-gil, Seocho-gu, Seoul, Republic of Korea',
    zh: '韩国忠清南道牙山市敦浦面忠武路1582',
  },
  branch6: {
    ko: '부산 지사',
    en: 'Busan Branch',
    zh: '釜山分公司',
  },
  branch6Address: {
    ko: '부산광역시 중구 중앙대로 88, 602호',
    en: '602, Jungang-daero, Jung-gu, Busan, Republic of Korea',
    zh: '韩国忠清南道牙山市汤井面汤井路21号路6',
  },
  branch7: {
    ko: '용인 지사',
    en: 'Yongin Branch',
    zh: '龙仁分公司',
  },
  branch7Address: {
    ko: '경기도 용인시 처인구 동부로 1204',
    en: '1204, Dongbu-ro, Cheoin-gu, Yongin-si, Gyeonggi-do, Republic of Korea',
    zh: '韩国京畿道龙仁市处仁区东部路1204',
  },

  branch3: {
    ko: '인주 지사',
    en: 'Inju Branch',
    zh: '仁州分公司',
  },
  branch3Address: {
    ko: '충청남도 아산시 인주면 인주산단로 23-30',
    en: '23-30, Injusan-dan-ro, Inju-myeon, Asan-si, Chungcheongnam-do, Republic of Korea',
    zh: '韩国忠清南道牙山市仁州面仁州山园路23-30',
  },
  branch4: {
    ko: '평택 영업소',
    en: 'Pyungtaek Branch',
    zh: '平泽分公司',
  },
  branch4Address: {
    ko: '경기도 평택시 경기대로 1286, 4층 406호 내 422호',
    en: '406-422, 1286, Kyunggi-daero, Pyeongtaek-si, Gyeonggi-do, Republic of Korea',
    zh: '韩国京畿道平泽市京畿大路1286, 4层406号内422号',
  },
  branch5: {
    ko: '탕정 영업소',
    en: 'Tangjeong Branch',
    zh: '汤井分公司',
  },
  branch5Address: {
    ko: '충청남도 아산시 탕정면 탕정로 21번길6',
    en:
      '6, Tangjeong-ro 21beon-gil, Tangjeong-myeon, Asan-si, Chungcheongnam-do, Republic of Korea',
    zh: '韩国忠清南道牙山市汤井面汤井路21号路6',
  },
}

/* <Footer /> =================================================================================== */
const Footer: FC<FooterType> = ({ isGroupSite }) => {
  return (
    <Box
      className={classes(isGroupSite ? '' : pt40, isGroupSite ? '' : pt16mdi, pb12)}
      baseline="background"
      row
      fullWidth
    >
      <Box maxWidth={MAX_WIDTH} centerAutoX baseline={'none'} fullWidth>
        <FooterContent isGroupSite={isGroupSite} />
      </Box>
    </Box>
  )
}

/* <FooterContent /> ============================================================================ */
export const FooterContent = (props: {
  row?: boolean
  className?: string
  isGroupSite?: boolean
  baseline?: ComponentBaselineType
}) => {
  const { row, className, baseline = 'background' } = props
  const { loc = 'ko' } = useParams<{ loc: 'en' | 'ko' }>()

  const FOOTER_ITEMS = [
    {
      title: locale.companyName[loc],
      className: mr88,
      pages: [
        {
          title: locale.home[loc],
          to: `${loc === 'ko' ? '' : '/en'}`,
        },
        {
          title: locale.companyShort[loc],
          to: `${loc === 'ko' ? '/a/company' : `/${loc}`}/a/company`,
        },
        {
          title: locale.services[loc],
          to: `${loc === 'ko' ? '/a/services' : `/${loc}`}/a/services`,
        },
        {
          title: 'Good Firms',
          to: { pathName: 'https://www.goodfirms.co/' },
        },
      ],
    },
    {
      title: locale.customerService[loc],
      className: mr48,
      pages: [
        {
          title: locale.contactUs[loc],
          to: `${loc === 'ko' ? '/a/contact-us' : `/${loc}`}/a/contact-us`,
        },
        {
          title: locale.agreement[loc],
          to: `${loc === 'ko' ? '/a/agreement' : `/${loc}`}/a/agreement`,
        },
      ],
    },
    {
      title: locale.branch[loc],
      className: ml40,
      pages: [
        {
          title: (
            <Box flex baseline="none" className={rowmdi}>
              <Box row baseline="none" className={mr24} maxWidth={320}>
                <Box className={mb12} baseline="none" row>
                  <Text className={mb12} size="sm" bold>
                    {locale.branch1[loc]}
                    <br />
                  </Text>{' '}
                  {locale.branch1Address[loc]} <br /> {'+82 041-532-3131'}
                </Box>
                <Box className={mb12} baseline="none" row>
                  <Text className={mb12} size="sm" bold>
                    {locale.branch2[loc]}
                    <br />
                  </Text>{' '}
                  {locale.branch2Address[loc]}
                </Box>
                <Box className={mb12} baseline="none" row>
                  <Text className={mb12} size="sm" bold>
                    {locale.branch6[loc]}
                    <br />
                  </Text>{' '}
                  {locale.branch6Address[loc]} <br /> {'+82 051-710-2710'}
                </Box>
              </Box>
              <Box row baseline="none" maxWidth={320}>
                <Box className={mb12} baseline="none" row>
                  <Text className={mb12} size="sm" bold>
                    {locale.branch4[loc]}
                    <br />
                  </Text>{' '}
                  {locale.branch4Address[loc]} <br /> {'+82 041-424-3104'}
                </Box>
                <Box className={mb12} baseline="none" row>
                  <Text className={mb12} size="sm" bold>
                    {locale.branch5[loc]}
                    <br />
                  </Text>{' '}
                  {locale.branch5Address[loc]}
                </Box>
                <Box className={mb12} baseline="none" row>
                  <Text className={mb12} size="sm" bold>
                    {locale.branch7[loc]}
                    <br />
                  </Text>{' '}
                  {locale.branch7Address[loc]} <br /> {'+82 031-337-0403'}
                </Box>
              </Box>
            </Box>
          ),
        },
      ],
    },
  ]

  return (
    <Box baseline={baseline} className={p8}>
      {!props.isGroupSite && (
        <Box
          baseline={baseline}
          className={classes(p8, STYLES.containerFooterContent, className)}
          row={row}
        >
          {FOOTER_ITEMS.map((menu: any, key: any) => {
            return (
              <Box
                baseline={baseline}
                className={classes(
                  STYLES.containerFooterItem,
                  mr0smi,
                  w100,
                  mb20,
                  mb32mdi,
                  m0mdi,
                  menu.className,
                )}
                key={key}
              >
                <Text size="sm" bold>
                  {menu.title}
                </Text>
                <ul className={classes(mt8, pl0)}>
                  {menu.pages.map((page: any, key: number) => {
                    return (
                      <Text
                        className={classes(mb16, STYLES.textFooterItem)}
                        size="sm"
                        as={<li />}
                        key={key}
                      >
                        {(page as any).to ? (
                          page.to?.pathName ? (
                            <a style={{ color: 'inherit' }} href={page.to.pathName}>
                              {page.title}
                            </a>
                          ) : (
                            <Link
                              to={(page as any).to}
                              target={page.target}
                              style={{ color: 'inherit' }}
                            >
                              {(page as any).title}
                            </Link>
                          )
                        ) : (
                          (page as any).title
                        )}
                      </Text>
                    )
                  })}
                </ul>
              </Box>
            )
          })}
        </Box>
      )}
      <Box baseline={baseline}>
        <Box
          baseline="none"
          className={classes(mt36, mt8mdi, pt8, STYLES.textCopyRight)}
          maxWidth={MAX_WIDTH}
          centerAutoX
          spaceBetween
        >
          <Text size="xs">© {date.getFullYear()} Samil Logistics. All rights reserved.</Text>
        </Box>
      </Box>
    </Box>
  )
}

/* Styles ======================================================================================= */
const STYLES = stylesheet({
  containerFooterContent: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    $nest: {
      ...createMediaQuery['md']({
        margin: 0,
        justifyContent: 'flex-start',
        flexDirection: 'column',
      }),
      '& a': {
        // color: COOL_GRAY,
      },
    },
  },
  containerFooterItem: {
    width: 'fit-content',
  },
  textFooterItem: {
    display: 'block',
  },
  textCopyRight: {
    textAlign: 'center',
  },
})

export default Footer
